import React from "react";
import Helmet from "react-helmet";
import config from "../../../data/SiteConfig";
import { Router } from "@reach/router";

class ProductRedirect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      cta: ""
    };
  }

  componentDidMount() {
    fetch(
      `${config.apiEndpoint}/api/v1/${config.apiPlatformId}/products/${
        this.props.productId
      }`,
      { cache: "no-store" }
    )
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            cta: result.cta
          });
        },
        error => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  render() {
    const { error, isLoaded, cta } = this.state;

    if (error)
      return (
        <div>
          Error:
          {error.message}
        </div>
      );

    return !isLoaded ? <div>Loading...</div> : (window.location.href = cta);
  }
}

class RedirectIndex extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Router>
          <ProductRedirect path="/redirect/:productId" />
        </Router>
      </React.Fragment>
    );
  }
}

export default RedirectIndex;
